<template lang="html">
  <div class="container text-left">
    <h1>Instruções</h1>
    <p>
      Podes criar versões miniatura dos teus links com o serviço
      <strong>clica.ai</strong>, que os torna mais fáceis de partilhar. Por
      exemplo, o link
      <a href="http://clica.ai/GbsRtkK">http://clica.ai/GbsRtkK</a> redireciona
      para
      <a
        href="https://www.apple.com/pt/shop/buy-iphone/iphone-xs/ecr%C3%A3-de-6,5-polegadas-64gb-prateado#01,10,20"
        >https://www.apple.com/pt/shop/buy-iphone/iphone-xs/ecrã-de-6,5-polegadas-64gb-prateado</a
      >.
    </p>

    <section>
      <h2>Cria o teu mini-URL:</h2>
      <ol>
        <li>
          Acede à nossa
          <router-link :to="{ name: 'home' }">página principal</router-link> em
          <a href="https://site.clica.ai">site.clica.ai</a>
        </li>
        <li>
          Se ainda não tiveres registo no nosso site,
          <a href="https://app.clica.ai">cria uma conta</a> clicando no botão
          "Registar" no menu de navegação superior (Opcional)
        </li>
        <li>
          <a href="https://app.clica.ai">Entra na tua conta</a> clicando no
          botão "Entrar" no menu de navegação superior (Opcional)
        </li>
        <li>
          Cola o teu URL de destino no formulário da
          <router-link :to="{ name: 'create-slug' }"
            >página de criação</router-link
          >
          de miniaturas ou diretamente na
          <router-link :to="{ name: 'home' }">página principal</router-link>
        </li>
        <li>
          Clica no botão "Criar" do formulário que usaste no passo anterior e
          irás obter o teu mini-URL
        </li>
      </ol>

      <p>
        Depois de criares os teus mini-links, poderás partilhá-los com amigos,
        clientes ou seguidores e todos irão ser redirecionados para a página que
        escolheste.
      </p>
    </section>

    <section>
      <h2>Acompanha os cliques do teu mini-URL:</h2>
      <ol>
        <li>
          Depois de entrares na <a href="https://app.clica.ai">tua conta</a>,
          poderás aceder ao
          <a href="https://app.clica.ai">painel de administração</a> que
          apresentará uma lista de todos os mini-URL que criaste
        </li>
        <li>
          Seleciona e clica no mini-URL da lista e verás um painel personalizado
          com várias estatísticas. Os utilizadores empresariais têm acesso a
          estatísticas extra (e.g. gráfico cliques / browser), pelo que poderás
          ter de
          <router-link :to="{ name: 'upgrade-account' }"
            >comprar uma subscrição</router-link
          >
          para ver todas as estatísticas disponíveis
        </li>
      </ol>
    </section>

    <section>
      <h2>Otimiza as tuas partilhas!</h2>
      <p>
        Mesmo na nossa versão gratuita é possível ver a que horas ou dia da
        semana os mini-links foram mais clicados. Aproveita esta informação para
        partilhares conteúdo nas alturas mais eficazes do dia e da semana para
        maximizares o teu alcance.
      </p>
    </section>

    <section>
      <h2>Conteúdo aceitável</h2>
      <p>
        O <strong>clica.ai</strong> tem um conjunto de regras firmes sobre que
        tipo de conteúdo pode ser utilizado como destino para os nossos
        mini-links. Por favor lê a informação na página dos
        <router-link :to="{ name: 'terms-conditions' }"
          >Termos e Condições</router-link
        >
        para te certificares que os teus mini-links estão em conformidade com as
        nossas regras. Qualquer conteúdo denunciado como malicioso ou que
        infrinja as regras referidas nos nossos termos poderá resultar na
        remoção dos mini-links ou na terminação do serviço sem aviso prévio.
      </p>
    </section>

    <section>
      <h2>Denunciar URLs</h2>
      <p>
        É possível reportar o conteúdo de URLs cujo destino infrinja os nossos
        termos e condições através
        <router-link :to="{ name: 'report-url' }">desta página</router-link>.
      </p>
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style lang="css" scoped>
h1 {
  padding-top: 0.5rem;
  padding-bottom: 0.8rem;
}
h2 {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}
</style>
