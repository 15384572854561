<template lang="html">
  <div>
    <div class="container my-8 py-md-11">
      <div class="row align-items-center">
        <div class="col-12 col-md-8 col-lg-6">
          <vue-typed-js
            :strings="[
              'escritórios',
              'aminhaempresa',
              'promoverão2020',
              'giveaway'
            ]"
            loop
            startDelay="1000"
            typeSpeed="7"
            backSpeed="7"
          >
            <h1 class="display-3 font-weight-bold text-black">
              Escolhe a miniatura.<br />
              <span class="text-primary">clica.ai/</span
              ><span class="typing text-primary"></span>
            </h1>
          </vue-typed-js>

          <!-- Text -->
          <p class="font-size-lg text-black-80 mb-6">
            Nós ajudamos-te a colocar o teu negócio na primeira página. Chama a
            atenção da tua audiência com um URL escolhido por ti!
          </p>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->

    <section class="py-4 py-md-6">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-12 col-md-6 col-lg-7 order-md-2">
            <!-- Image -->
            <img
              src="assets/img/illustrations/illustration-3.png"
              alt="..."
              class="img-fluid mb-6 mb-md-8 mb-md-0 aos-init aos-animate"
              data-aos="fade-left"
            />
          </div>
          <div
            class="col-12 col-md-6 col-lg-5 order-md-1 aos-init aos-animate"
            data-aos="fade-right"
          >
            <!-- Heading -->
            <h2 class="font-weight-bold">
              Distingue-te do resto dos nossos clientes.
            </h2>

            <!-- Text -->
            <p class="font-size-lg text-muted mb-0">
              As miniaturas personalizadas são mais fáceis de decorar e são
              ótimas para campanhas promocionais especialmente importantes.
            </p>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>

    <section class="py-4 py-md-6">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div
            class="col-12 col-md-6 col-lg-5 order-md-2 aos-init aos-animate"
            data-aos="fade-right"
          >
            <!-- Heading -->
            <h2 class="font-weight-bold">
              Alcança mais pessoas na tua plataforma.
            </h2>

            <!-- Text -->
            <p class="font-size-lg text-muted mb-0">
              Quer sejas um(a) influencer, CEO de uma empresa ou um
              empreendedor, as miniaturas personalizadas ajudam a construir a
              imagem da tua marca.
            </p>
          </div>
          <div class="col-12 col-md-6 col-lg-7 order-md-1">
            <!-- Image -->
            <img
              src="assets/img/illustrations/illustration-6.png"
              alt="..."
              class="img-fluid mb-6 mb-md-8 mb-md-0 aos-init aos-animate"
              data-aos="fade-left"
            />
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>

    <!-- FAQ
  ================================================== -->
    <section class="bg-dark">
      <div class="container pt-8 pt-md-11">
        <div class="row">
          <div class="col-12 col-md-6">
            <!-- Item -->
            <div class="d-flex">
              <!-- Badge -->
              <div class="badge badge-lg badge-rounded-circle badge-success">
                <span>?</span>
              </div>

              <div class="ml-5">
                <!-- Heading -->
                <h4 class="text-white">
                  Quanto custa uma miniatura personalizada?
                </h4>

                <!-- Text -->
                <p class="text-muted mb-6 mb-md-8">
                  O preço de cada miniatura personalizada varia consoante o
                  número de letras e números utilizados. Quanto maior for o nome
                  da miniatura, menor será o custo. O custo final será
                  apresentado no menu de criação de URLs.
                </p>
              </div>
            </div>

            <!-- Item -->
            <div class="d-flex">
              <!-- Badge -->
              <div class="badge badge-lg badge-rounded-circle badge-success">
                <span>?</span>
              </div>

              <div class="ml-5">
                <!-- Heading -->
                <h4 class="text-white">
                  As miniaturas personalizadas têm mensalidade?
                </h4>

                <!-- Text -->
                <p class="text-muted mb-6 mb-md-0">
                  Não! O custo de cada miniatura personalizada é pago apenas uma
                  vez e não tem custos mensais associados.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <!-- Item -->
            <div class="d-flex">
              <!-- Badge -->
              <div class="badge badge-lg badge-rounded-circle badge-success">
                <span>?</span>
              </div>

              <div class="ml-5">
                <!-- Heading -->
                <h4 class="text-white">
                  Quanto tempo dura uma miniatura personalizada?
                </h4>

                <!-- Text -->
                <p class="text-muted mb-6 mb-md-8">
                  Depois de comprares uma miniatura personalizada ela ficará
                  associada à tua conta. Enquanto a tua conta existir a
                  miniatura é tua, e poderás mudar o destino da miniatura!
                </p>
              </div>
            </div>

            <!-- Item -->
            <div class="d-flex">
              <!-- Badge -->
              <div class="badge badge-lg badge-rounded-circle badge-success">
                <span>?</span>
              </div>

              <div class="ml-5">
                <!-- Heading -->
                <h4 class="text-white">
                  Como vejo as estatísticas da minha miniatura personalizada?
                </h4>

                <!-- Text -->
                <p class="text-muted mb-6 mb-md-0">
                  Para teres acesso a todos os gráficos e resultados dos cliques
                  tens que ter uma subscrição ativa, independentemente da
                  miniatura que queiras analisar.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>

    <!-- CTA
  ================================================== -->
    <section class="py-8 py-md-11 bg-dark">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 text-center">
            <!-- Badge -->
            <span class="badge badge-pill badge-gray-700-soft mb-4">
              <span class="h6 font-weight-bold text-uppercase"
                >Começa a usar</span
              >
            </span>

            <!-- Heading -->
            <h1 class="display-4 text-white">
              Regista-te para poderes usar miniaturas personalizadas.
            </h1>

            <!-- Text -->
            <p class="font-size-lg text-muted mb-6 mb-md-8">
              O clica.ai personaliza e acompanha as tuas campanhas, oferece
              estatísticas detalhadas de cada clique e garante a privacidade dos
              teus utilizadores.<br />
              Começa hoje a partilhar links e conhece melhor o teu público-alvo.
            </p>

            <!-- Button -->
            <a href="https://app.clica.ai" class="btn btn-success lift">
              Criar conta <i class="fe fe-arrow-right"></i>
            </a>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style lang="css"></style>
