<template>
  <div class="">
    <!-- FORM
    ================================================== -->
    <section class="pt-8 pt-md-11 pb-8 pb-md-14">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 text-center">
            <!-- Heading -->
            <h2 class="font-weight-bold">
              Denunciar URL
            </h2>

            <!-- Text -->
            <p class="font-size-lg text-muted mb-7 mb-md-9">
              Preencha a informação necessária
            </p>
          </div>
        </div>
        <!-- / .row -->
        <div class="row justify-content-center">
          <div class="col-12 col-md-12 col-lg-10">
            <!-- Form -->
            <form @submit.prevent="reportUrl" novalidate>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group mb-5">
                    <label for="slug">
                      URL / Miniatura
                    </label>

                    <BaseInput
                      id="slug"
                      placeholder="URL"
                      v-model.trim="report.slug"
                      required
                      type="text"
                      @blur="$v.report.slug.$touch()"
                      :dirty="$v.report.slug.$dirty"
                      :error="$v.report.slug.$error"
                    />
                    <BaseValidation :messages="getValidationObjects().slug" />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group mb-5">
                    <label for="reason">
                      Motivo
                    </label>

                    <BaseSelect
                      id="reason"
                      defaultOptionPlaceholder="Escolhe um motivo..."
                      v-model="report.reason"
                      :options="motives"
                      @blur="$v.report.reason.$touch()"
                      :dirty="$v.report.reason.$dirty"
                      :error="$v.report.reason.$error"
                    />
                    <BaseValidation :messages="getValidationObjects().reason" />
                  </div>
                </div>
              </div>
              <!-- / .row -->
              <div class="row">
                <div class="col-12">
                  <div class="form-group mb-7 mb-md-9">
                    <!-- Label -->
                    <label for="additionalInfo">
                      Informação Adicional
                    </label>

                    <!-- Input -->
                    <textarea
                      class="form-control"
                      id="additionalInfo"
                      rows="5"
                      placeholder="Mais Informação"
                      v-model="report.additionalInfo"
                    ></textarea>
                  </div>
                </div>
              </div>
              <!-- / .row -->
              <div class="row justify-content-center">
                <div class="col-auto">
                  <vue-recaptcha
                    @verify="onCaptchaVerified"
                    @expired="onCaptchaExpired"
                    ref="reportUrlRecaptcha"
                    sitekey="6LdAVJ4UAAAAAEEN96jVBus_hlaJIVyOsD-9N4Vp"
                    size="invisible"
                  ></vue-recaptcha>
                  <BaseButton
                    type="submit"
                    :status="buttonStatus"
                    buttonExternalClasses="lift"
                    >Denunciar</BaseButton
                  >
                </div>
              </div>
              <!-- / .row -->
            </form>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueRecaptcha from "vue-recaptcha";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      report: this.getFreshReportObject(),
      motives: [
        {
          value: "illegal",
          text: "Conteúdo Ilegal"
        },
        {
          value: "shocking",
          text: "Conteúdo Chocante"
        },
        {
          value: "virusspam",
          text: "Vírus / SPAM"
        },
        {
          value: "executable",
          text: "Ficheiro Executável"
        },
        {
          value: "other",
          text: "Outro"
        }
      ]
    };
  },
  components: {
    "vue-recaptcha": VueRecaptcha
  },
  computed: {
    ...mapGetters({
      pending: "api/isPending"
    }),
    isReportPending() {
      // get login api status for button feedback
      return this.pending("reportUrl");
    },
    buttonStatus() {
      return this.$v.$invalid
        ? "disabled"
        : this.isReportPending
        ? "loading"
        : "default";
    }
  },
  methods: {
    getFreshReportObject() {
      return {
        reason: "",
        slug: "",
        additionalInfo: ""
      };
    },
    getValidationObjects() {
      return {
        reason: [
          {
            condition: !this.$v.report.reason.required,
            message: "Motivo obrigatório"
          }
        ],
        slug: [
          {
            condition: !this.$v.report.slug.required,
            message: "URL obrigatório"
          }
        ]
      };
    },
    reportUrl() {
      this.$v.$touch(); // makes the form dirty to show errors
      if (this.$v.$pending || this.$v.$error || this.$v.$invalid) return;

      //   console.log("report url form submitted.");
      this.$refs.reportUrlRecaptcha.execute();
    },
    // eslint-disable-next-line no-unused-vars
    onCaptchaExpired(err) {
      this.$refs.reportUrlRecaptcha.reset();
    },
    onCaptchaVerified: function(recaptchaToken) {
      this.$refs.reportUrlRecaptcha.reset();

      this.$store
        .dispatch("api/reportUrl", {
          motive: this.report.reason,
          comment: this.report.additionalInfo,
          slug: this.report.slug,
          recaptchaToken: recaptchaToken
        })
        .then(() => {
          //   console.log("submission successful, cleaning...");
          this.report = this.getFreshReportObject();
          this.$v.$reset();
        })
        .catch(() => {
          //   console.log("error from server...");
        });
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  validations: {
    report: {
      reason: {
        required
      },
      slug: {
        required
      }
    }
  }
};
</script>

<style scoped></style>
