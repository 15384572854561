<template lang="html">
  <section class="pt-8 pt-md-11 pb-8 pb-md-14">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md">
          <!-- Heading -->
          <h1 class="display-4 mb-2">
            Preçário
          </h1>

          <!-- Text -->
          <p class="font-size-lg text-gray-700 mb-md-0">
            Atualizado a 10 de junho de 2019
          </p>
        </div>
        <div class="col-auto">
          <!-- Buttons -->
          <button class="btn btn-primary-soft" @click="print">
            Imprimir
          </button>
        </div>
      </div>
      <!-- / .row -->
      <div class="row">
        <div class="col-12">
          <!-- Divider -->
          <hr class="my-6 my-md-8" />
        </div>
      </div>
      <!-- / .row -->
      <div class="row">
        <div class="col-12 col-md-8">
          <!-- Text -->
          <p class="font-size-lg text-gray-800 mb-6 mb-md-8">
            Esta página descreve os vários níveis de serviço que oferecemos bem
            como os preços que praticamos. Também incluímos informação sobre a
            nossa versão gratuita.
          </p>

          <!-- Heading -->
          <h3 class="mb-5">
            Serviço gratuito sem registo
          </h3>

          <!-- Text -->
          <p class="text-gray-800">
            O <strong>clica.ai</strong> deixa-te criar um URL sem ser necessário
            registar uma conta
            <router-link :to="{ name: 'home' }">nesta página</router-link>. Os
            URLs que forem criados desta forma não têm limites de utilização mas
            não vais conseguir ver as estatísticas dos utilizadores. Se quiseres
            usar o clica.ai de forma gratuita e quiseres ter acesso a
            estatísticas básicas,
            <a href="https://app.clica.ai">cria uma conta</a>.
          </p>

          <!-- Heading -->
          <h3 class="mb-5">
            Serviço gratuito com registo
          </h3>

          <!-- Text -->
          <p class="text-gray-800">
            Ao <a href="https://app.clica.ai">criar uma conta</a> clica.ai
            consegues criar até 50 URLs novos por mês e ver estatísticas básicas
            sobre cada URL que criaste. Além disso, torna-se possível comprar
            vários extras para cada URL que podem ser do teu interesse.
          </p>

          <!-- Heading -->
          <h3 class="mb-5">
            <a name="#extras">Extras</a>
          </h3>

          <!-- Text -->
          <p class="text-gray-800">
            Vendemos uma série de extras antes ou depois da criação de URLs que
            podem ajudar a ganhar mais visibilidade, personalizar o serviço aos
            teus utilizadores ou desbloquear estatísticas avançadas. O custo
            destes extras é pago apenas uma vez.
          </p>

          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Extra</th>
                <th scope="col">Descrição</th>
                <th scope="col">Custo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Alterar Destino</th>
                <td>
                  Redireciona os teus utilizadores para outro destino diferente
                  do inicial.
                </td>
                <td>5€</td>
              </tr>
              <tr>
                <th scope="row">Destino Dinâmico</th>
                <td>
                  Programa um único URL para redirecionar os teus utilizadores
                  para destinos diferentes consoante o sistema operativo ou
                  localização geográfica.
                </td>
                <td>9€</td>
              </tr>
              <tr>
                <th scope="row">URL Personalizado</th>
                <td>
                  Escolhe o nome do teu link (ex.&nbsp;clica.ai/primavera).
                </td>
                <td>Variável¹</td>
              </tr>
            </tbody>
          </table>

          <!-- Text -->
          <p class="text-muted">
            ¹ O custo dos URLs personalizados é inversamente proporcional ao
            número de caracteres. Por exemplo, o nome "StandDeCarros" custa
            menos do que "carros" porque tem mais caracteres. O custo exato de
            cada URL personalizado será apresentado quando selecionares esta
            opção.
          </p>

          <!-- Heading -->
          <h3 class="mb-5">
            <a name="#extras">Serviço com mensalidade</a>
          </h3>

          <!-- Text -->
          <p class="text-gray-800">
            Disponibilizamos uma versão do clica.ai que mostra estatísticas
            avançadas sobre os cliques feitos nos URLs. Os clientes com esta
            versão ativa poderão ver quais as melhores horas para publicar
            links, qual a zona geográfica mais popular e ver vários gráficos que
            mostram a evolução dos cliques ao longo do tempo. Este serviço pode
            ser ativo a qualquer altura nas definições da conta e tem um custo
            variável que explicamos de seguida.
          </p>

          <p class="text-gray-800">
            O custo mensal da versão paga do clica.ai depende de dois fatores: o
            número de links criados nesse mês e o número total de cliques feitos
            nesse mês. Todos os clientes têm direito a um mínimo mensal
            gratuíto:
          </p>

          <table class="table">
            <caption>
              Quota Mensal Grátis
            </caption>
            <tbody>
              <tr>
                <th scope="row">Cliques</th>
                <td>500</td>
              </tr>
              <tr>
                <th scope="row">URLs criados</th>
                <td>5</td>
              </tr>
            </tbody>
          </table>

          <p class="text-gray-800">
            Apenas os valores em excesso da quota gratuita mensal serão
            contabilizados para pagamento. O custo mensal final é igual à soma
            do custo dos cliques e do custo dos URLs criados. Estes custos estão
            representados nas duas tabelas seguintes:
          </p>

          <table class="table">
            <caption>
              Custos por clique
            </caption>
            <tbody>
              <tr>
                <th scope="row">Primeiros 10.000 cliques</th>
                <td>0,00099€ / clique</td>
              </tr>
              <tr>
                <th scope="row">10.001 até 50.000 cliques</th>
                <td>0,00079€ / clique</td>
              </tr>
              <tr>
                <th scope="row">50.000 até 250.000 cliques</th>
                <td>0,00049€ / clique</td>
              </tr>
              <tr>
                <th scope="row">Acima de 250.000 cliques</th>
                <td>0,00025€ / clique</td>
              </tr>
            </tbody>
          </table>

          <table class="table">
            <caption>
              Custos por URL
            </caption>
            <tbody>
              <tr>
                <th scope="row">Primeiros 25 URLs</th>
                <td>0,099€ / URL</td>
              </tr>
              <tr>
                <th scope="row">25 até 100 URL</th>
                <td>0,0069€ / URL</td>
              </tr>
              <tr>
                <th scope="row">Acima de 100 URLs</th>
                <td>0,0049€ / URL</td>
              </tr>
            </tbody>
          </table>

          <!-- Text -->
          <p class="text-muted">
            Exemplo 1: Um YouTuber cria 25 URLs para ver quantos subscritores
            clicam em links de parcerias e tem um total de 450 cliques. O
            cálculo do total mensal é feito da seguinte forma:<br />
            <code
              >(25-5) x 0,099€ + <strike>(450-500) x 0,00099€</strike> =
              1,98€</code
            ><br />
            Não é cobrado qualquer valor pelos cliques visto não ter sido
            ultrapassado o limite mensal gratuíto. Já na parcela de URLs, o
            limite gratuito foi ultrapassado por 20 URLs que são cobrados ao
            preço normal.
          </p>

          <!-- Text -->
          <p class="text-muted">
            Exemplo 2: Uma empresa do Porto num dado mês cria 10 URLs para uma
            campanha de marketing via SMS e recebe 45.000 cliques. O valor a
            pagar nesse mês é:<br /><code
              >(10-5) x 0,099€ + 10.000 x 0,00099€ + 34.500 x 0,00079€ =
              37,75€</code
            ><br />
            A quota mensal gratuita foi ultrapassada por 5 URLs e 44.500
            cliques. Os primeiros 10.000 cliques são cobrados a um preço
            diferente dos restantes, de acordo com a tabela.
          </p>

          <!-- Heading -->
          <h5 class="mb-4">
            Ver estatísticas de URLs antigos
          </h5>

          <p class="text-gray-800">
            Os clientes que ativarem o serviço com mensalidade poderão começar a
            ver as estatísticas de todos os URLs criados depois de ativar a
            mensalidade. Isto significa que se a conta tiver URLs antigos, as
            estatísticas antigas terão de ser obtidas a partir do nosso arquivo.
            Esta operação tem um custo único que depende do número de URLs e
            cliques no arquivo e segue a seguinte tabela de custos:
          </p>

          <table class="table">
            <caption>
              Custos de Desarquivação
            </caption>
            <tbody>
              <tr>
                <th scope="row">Cliques</th>
                <td>0,0059€ / clique</td>
              </tr>
              <tr>
                <th scope="row">URLs</th>
                <td>0,45€ / URL</td>
              </tr>
            </tbody>
          </table>

          <p class="text-muted">
            Oferecemos descontos de volume para clientes que queiram desarquivar
            as estatísticas de mais de 20 URLs.
            <a href="mailto:vendas@clica.ai" class="text-reset">Contacta-nos</a>
            e pede um orçamento.<br />
            Só garantimos estatísticas de URLs que tenham sido criados nos
            últimos 5 anos.
          </p>
        </div>
        <div class="col-12 col-md-4">
          <!-- Card -->
          <div class="card shadow-light-lg">
            <div class="card-body">
              <!-- Heading -->
              <h4>
                Tens alguma pergunta?
              </h4>

              <!-- Text -->
              <p class="font-size-sm text-gray-800 mb-5">
                Tentámos fazer o nosso preçário justo tanto para startups como
                para grandes empresas, mas sabemos que alguns conceitos podem
                ser um pouco confusos. Se tiveres alguma dúvida em relação aos
                nossos preços, estamos à tua disposição!
              </p>

              <!-- Heading -->
              <h6 class="font-weight-bold text-uppercase text-gray-700 mb-2">
                Envia-nos um email
              </h6>

              <!-- Text -->
              <p class="font-size-sm mb-0">
                <a href="mailto:vendas@clica.ai" class="text-reset"
                  >vendas@clica.ai</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>
</template>

<script>
export default {
  methods: {
    print: () => {
      window.print();
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style>
table caption {
  caption-side: top;
}
</style>
