<template lang="html">
  <section class="pt-8 pt-md-11 pb-8 pb-md-14">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md">
          <!-- Heading -->
          <h1 class="display-4 mb-2">
            Termos de Serviço
          </h1>

          <!-- Text -->
          <p class="font-size-lg text-gray-700 mb-md-0">
            Atualizado a 9 de junho de 2019
          </p>
        </div>
        <div class="col-auto">
          <!-- Buttons -->
          <button class="btn btn-primary-soft" @click="print">
            Imprimir
          </button>
        </div>
      </div>
      <!-- / .row -->
      <div class="row">
        <div class="col-12">
          <!-- Divider -->
          <hr class="my-6 my-md-8" />
        </div>
      </div>
      <!-- / .row -->
      <div class="row">
        <div class="col-12 col-md-8">
          <!-- Text -->
          <p class="font-size-lg text-gray-800 mb-6 mb-md-8">
            Nesta página encontram-se os nossos termos de serviço que incluem
            alguma informação legal, mas de forma resumida, o clica.ai é um
            serviço independente que não partilha informação com terceiros e
            protege tanto os dados dos utilizadores registados como os dos
            clientes finais que clicam nos nossos links.
          </p>

          <!-- Heading -->
          <h3 class="mb-5">
            Conteúdo Ilegal
          </h3>

          <!-- Text -->
          <p class="text-gray-800">
            É expressamente proibido o uso do nosso serviço para redirecionar
            para conteúdo ilícito de acordo com as leis Portuguesas, Europeias,
            ou as leis em vigor no país de origem do utilizador.<br />
            Se acredita que um dos nossos URLs está a infringir esta regra, por
            favor
            <router-link :to="{ name: 'report-url' }">denuncie</router-link>.
          </p>

          <!-- Heading -->
          <h3 class="mb-5">
            SPAM, Vírus e Software Malicioso
          </h3>

          <!-- Text -->
          <p class="text-gray-800">
            Pedimos aos nossos utilizadores que não usem o nosso serviço com fim
            de redirecionar para sites que contenham software malicioso ou que
            difundam mensagens não solicitadas (spam). Os sites com conteúdo
            spam tipicamente são de fraca qualidade e existem apenas para
            mostrar publicidade não solicitada ou conteúdo questionável. Os
            sites com software malicioso são sites que transferem conteúdo que
            pode interferir com a operação normal dos computadores ou mesmo
            danificar a informação neles guardada através de programas
            maliciosos (e.g. ransomware).<br />
            Se acredita que um dos nossos URLs está a infringir esta regra, por
            favor
            <router-link :to="{ name: 'report-url' }">denuncie</router-link>.
          </p>

          <!-- Heading -->
          <h3 class="mb-5">
            Outro Conteúdo
          </h3>

          <!-- Text -->
          <p class="text-gray-800">
            Moderamos o nosso conteúdo perante denúncias feitas por outros
            utilizadores, e através deste mecanismo poderemos bloquear o acesso
            aos mini-links acusados se houver uma justificação legítima para
            bloquear o conteúdo. Em casos mais graves em que se verifique uma
            flagrante infração aos termos aqui listados, reservamos o direito de
            suspender o serviço aos utilizadores responsáveis.<br />
            Se acredita que um dos nossos URLs está a infringir esta regra, por
            favor
            <router-link :to="{ name: 'report-url' }">denuncie</router-link>.
          </p>
        </div>
        <div class="col-12 col-md-4">
          <!-- Card -->
          <div class="card shadow-light-lg">
            <div class="card-body">
              <!-- Heading -->
              <h4>
                Tens alguma pergunta?
              </h4>

              <!-- Text -->
              <p class="font-size-sm text-gray-800 mb-5">
                Se não conseguires encontrar o que procuras ou quiseres
                clarificar algum dos pontos aqui listados, estamos ao teu dispor
                para esclarecer qualquer dúvida!
              </p>

              <!-- Heading -->
              <h6 class="font-weight-bold text-uppercase text-gray-700 mb-2">
                Envia-nos um email
              </h6>

              <!-- Text -->
              <p class="font-size-sm mb-0">
                <a href="mailto:suporte@clica.ai" class="text-reset"
                  >suporte@clica.ai</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>
</template>

<script>
export default {
  methods: {
    print: () => {
      window.print();
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style></style>
