<template lang="html">
  <section class="pt-8 pt-md-11 pb-8 pb-md-14">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md">
          <!-- Heading -->
          <h1 class="display-4 mb-2">
            Política de Privacidade
          </h1>

          <!-- Text -->
          <p class="font-size-lg text-gray-700 mb-md-0">
            Atualizado a 10 de junho de 2019
          </p>
        </div>
        <div class="col-auto">
          <!-- Buttons -->
          <button class="btn btn-primary-soft" @click="print">
            Imprimir
          </button>
        </div>
      </div>
      <!-- / .row -->
      <div class="row">
        <div class="col-12">
          <!-- Divider -->
          <hr class="my-6 my-md-8" />
        </div>
      </div>
      <!-- / .row -->
      <div class="row">
        <div class="col-12 col-md-8">
          <!-- Text -->
          <p class="font-size-lg text-gray-800 mb-6 mb-md-8">
            O <strong>clica.ai</strong> é um serviço de miniaturização de URLs,
            acessível de forma anónima e registada, e é necessária alguma
            informação para processar os pedidos que recebemos. Esta página
            descreve toda a informação recolhida, guardada e partilhada, estando
            implícita a nossa conformidade com a legislação Europeia que abrange
            a proteção de dados dos utilizadores (RGPD).
          </p>

          <!-- Heading -->
          <h3 class="mb-5">
            Mecanismos de Segurança
          </h3>

          <!-- Text -->
          <p class="text-gray-800">
            A privacidade e segurança dos nossos utilizador são as nossas
            maiores prioridades. Onde possível, o nosso serviço utiliza
            mecanismos de segurança como ligações seguras (SSL/TLS) para
            proteger toda a informação que nos é fornecida. Para a informação
            mais sensível, como palavras-passe ou endereços IP, aplicamos
            transformações segundo práticas modernas de segurança que nos
            permitem garantir que a informação não só não é guardada de modo
            legível, como na eventualidade de um ataque informático a informação
            sensível permanece obfuscada.
          </p>

          <!-- Heading -->
          <h3 class="mb-5">
            Informação Recolhida
          </h3>

          <!-- Text -->
          <p class="text-gray-800">
            Apenas recolhemos a informação indispensável ao fornecimento do
            nosso serviço, e a informação que possuímos não é usada para
            qualquer outro fim. Utilizamos mecanismos de agregação para
            anonimizar parte da informação recolhida para minimizar a quantidade
            de informação em nossa posse. No âmbito do nosso serviço de
            miniaturização de URLs e respetivo acompanhamento estatístico são
            recolhidos dados dos utilizadores, separados nas duas seguintes
            categorias: utilizadores anónimos e utilizadores registados.<br />
            Sobre os utilizadores registados, a quem fornecemos o acompanhamento
            do desempenho dos mini-URLs, recolhemos a seguinte informação:
          </p>

          <!-- List -->
          <div class="d-flex">
            <!-- Badge -->
            <span
              class="badge badge-rounded-circle badge-success-soft mt-1 mr-4"
            >
              <i class="fe fe-check"></i>
            </span>

            <!-- Text -->
            <p class="text-gray-800">
              Nome
            </p>
          </div>
          <div class="d-flex">
            <!-- Badge -->
            <span
              class="badge badge-rounded-circle badge-success-soft mt-1 mr-4"
            >
              <i class="fe fe-check"></i>
            </span>

            <!-- Text -->
            <p class="text-gray-800">
              Endereço de email
            </p>
          </div>
          <div class="d-flex">
            <!-- Badge -->
            <span
              class="badge badge-rounded-circle badge-success-soft mt-1 mr-4"
            >
              <i class="fe fe-check"></i>
            </span>

            <!-- Text -->
            <p class="text-gray-800">
              Palavra-passe (posteriormente obfuscada irreversivelmente)
            </p>
          </div>
          <div class="d-flex">
            <!-- Badge -->
            <span
              class="badge badge-rounded-circle badge-success-soft mt-1 mr-4"
            >
              <i class="fe fe-check"></i>
            </span>

            <!-- Text -->
            <p class="text-gray-800">
              Data e hora de registo
            </p>
          </div>
          <div class="d-flex">
            <!-- Badge -->
            <span
              class="badge badge-rounded-circle badge-success-soft mt-1 mr-4"
            >
              <i class="fe fe-check"></i>
            </span>

            <!-- Text -->
            <p class="text-gray-800">
              Informação de pagamento (pedida ao utilizador quando adquire
              serviços pagos)
            </p>
          </div>

          <p class="text-gray-800">
            Sobre os utilizadores não registados, que representam as pessoas que
            clicam nos URLs do <strong>clica.ai</strong>, ao serem
            redirecionados através dos nossos servidores fornecem-nos a seguinte
            informação para fins de estatísticas:
          </p>

          <div class="d-flex">
            <!-- Badge -->
            <span
              class="badge badge-rounded-circle badge-success-soft mt-1 mr-4"
            >
              <i class="fe fe-check"></i>
            </span>

            <!-- Text -->
            <p class="text-gray-800">
              Identificador do browser (fabricante e versão)
            </p>
          </div>
          <div class="d-flex">
            <!-- Badge -->
            <span
              class="badge badge-rounded-circle badge-success-soft mt-1 mr-4"
            >
              <i class="fe fe-check"></i>
            </span>

            <!-- Text -->
            <p class="text-gray-800">
              Identificador do sistema operativo
            </p>
          </div>
          <div class="d-flex">
            <!-- Badge -->
            <span
              class="badge badge-rounded-circle badge-success-soft mt-1 mr-4"
            >
              <i class="fe fe-check"></i>
            </span>

            <!-- Text -->
            <p class="text-gray-800">
              Data e hora do clique
            </p>
          </div>
          <div class="d-flex">
            <!-- Badge -->
            <span
              class="badge badge-rounded-circle badge-success-soft mt-1 mr-4"
            >
              <i class="fe fe-check"></i>
            </span>

            <!-- Text -->
            <p class="text-gray-800">
              Localização aproximada (obtida através do endereço IP)
            </p>
          </div>

          <!-- Heading -->
          <h3 class="mb-5">
            Partilha da Informação
          </h3>

          <!-- Text -->
          <p class="text-gray-800">
            O clica.ai foi criado pela
            <a href="https://bitquiver.co">BitQuiver</a>, um grupo que desenha e
            cria produtos e serviços online. Os criadores da BitQuiver garantem
            que a privacidade dos seus utilizadores não está à venda e que não
            vendemos nem partilhamos quaisquer dados do nosso serviço com
            terceiros.
          </p>

          <!-- Heading -->
          <h3 class="mb-5">
            Serviços Externos
          </h3>

          <!-- Text -->
          <p class="text-gray-800">
            O serviço disponibilizado pelo clica.ai depende da introdução de
            diversos dados para o seu funcionamento. Utilizamos o serviço
            <a
              href="https://www.google.com/recaptcha/intro/v3.html"
              target="_blank"
              >reCAPTCHA</a
            >, fornecido pela Google, para proteção contra atos maliciosos como
            a introdução automatizada de dados, e para determinar se os dados
            que recebemos foram produzidos por pessoas e não por robôs. O
            serviço funciona lendo alguma informação do navegador dos
            utilizadores para inferir se o utilizador é humano, providenciando
            um desafio visual ou auditivo em casos duvidosos.
          </p>
          <p class="text-gray-800">
            A nossa integração com o serviço reCAPTCHA é feita de forma
            invisível para o utilizador e está presente em várias páginas do
            nosso site. A interação dos utilizadores com este serviço está ainda
            sujeita à
            <a
              href="https://policies.google.com/privacy?hl=pt_PT"
              target="_blank"
              >Política de Privacidade</a
            >
            e aos
            <a href="https://policies.google.com/terms?hl=pt_PT" target="_blank"
              >Termos de Utilização</a
            >
            da
            <a href="https://www.google.com/about/" target="_blank">Google</a>.
          </p>
        </div>
        <div class="col-12 col-md-4">
          <!-- Card -->
          <div class="card shadow-light-lg">
            <div class="card-body">
              <!-- Heading -->
              <h4>
                Tens alguma pergunta?
              </h4>

              <!-- Text -->
              <p class="font-size-sm text-gray-800 mb-5">
                Se não conseguires encontrar o que procuras ou quiseres
                clarificar algum dos pontos aqui listados, estamos ao teu dispor
                para esclarecer qualquer dúvida!
              </p>

              <!-- Heading -->
              <h6 class="font-weight-bold text-uppercase text-gray-700 mb-2">
                Envia-nos um email
              </h6>

              <!-- Text -->
              <p class="font-size-sm mb-0">
                <a href="mailto:suporte@clica.ai" class="text-reset"
                  >suporte@clica.ai</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>
</template>

<script>
export default {
  methods: {
    print: () => {
      window.print();
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style></style>
